<template>

  <div class="tags">
    <h2 class="ptl">{{ $t('journal_view_column_tags') }}</h2>
    
    <div class="tag_list">
      <NuxtLink v-for="(opt,ind) in data" class="tag" :to="'/journal/tags/' + opt.link" :key="ind">
        <div class="nm">{{ opt.name }}</div>
        <div class="lbl">{{ opt.count }}</div>
      </NuxtLink>
    </div>

  </div>

</template>

<script>
 

export default {   
  components: {   

  },
  props: [
    'data'
    //data:{
      //type: Object
    //}
  ],
  data () {
    return {            
    }
  }, 
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
/* TAG */
  
  .tag_list{
    display: flex;
    flex-wrap: wrap;
  }
  .tag_list .tag{
    color: #4a1700;
    background-color: #ffd6c5;
    border-radius: 20px;
    padding: 2px 15px;
    display: flex;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .tag_list .tag .nm{white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 90px;}
  .tag_list .tag .lbl{
    margin-left: 5px;
    font-weight: 500;
  }
  .tag_list .tag:hover{
    opacity:1!important;
  }
  
  .ptl{
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: normal;
  }
  
</style>


<style>
 
  
</style>