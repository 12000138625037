<template>

  <div class="vacancy">
    <h2 class="ptl">{{ $t('journal_view_careers_title') }}
      <NuxtLink class="ptla" to="/journal/career-on-growdiaries">{{ $t('universal_see_more') }}</NuxtLink>
    </h2>
    <div v-html="data" class="vacancy_list"></div>
  </div>

</template>

<script>
 

export default {   
  components: {   

  },
  props: [
    'data'
    //data:{
      //type: Object
    //}
  ],
  data () {
    return {            
    }
  }, 
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
 .vacancy_list p{
    padding: 10px 13px;
    background-color: var(--un-background-color-gray);
    border-radius: 5px;
  }
  
  
  .journal .vacancy{
    margin-bottom: 3rem;
  } 
  .journal .ptl{
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: normal;
  }

  
  .journal .ptla{
  text-transform: initial;
  color: darkgray;
  text-decoration: underline;
  font-size: 1.2rem;
  margin-left: 7px;
  display: inline-block;
  cursor: pointer;
  }
  
</style>


<style>
 
 .vacancy_list p{
    padding: 10px 13px;
    background-color: var(--un-background-color-gray);
    border-radius: 5px;
  }
  
</style>